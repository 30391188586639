'use client';

import { useState } from 'react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Eye as EyeIcon } from '@phosphor-icons/react/dist/ssr/Eye';
import { EyeSlash as EyeSlashIcon } from '@phosphor-icons/react/dist/ssr/EyeSlash';
import Image from 'next/image';
import RouterLink from 'next/link';
import { z as zod } from 'zod';

import { useNavigateTo } from '@/hooks/use-navigato-to';
import { paths } from '@/paths';
import { useVerifyCredentialsLazyQuery } from "@/generated/graphql";
import { setAuthCookies } from "@/auth/server-actions";

const schema = zod.object({
    email: zod.string().min(1, { message: 'Email is required' }).email(),
    password: zod.string().min(1, { message: 'Password is required' }),
});

type Values = zod.infer<typeof schema>;

const defaultValues = { email: '', password: '' } satisfies Values;

export function SignInForm() {
    const { navigateToProductList } = useNavigateTo();

    const [showPassword, setShowPassword] = useState<boolean>();
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [onVerifyCredentials] = useVerifyCredentialsLazyQuery({
        onError: () => {
            setError(true);
            setIsPending(false);
        }
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Values>({ defaultValues, resolver: zodResolver(schema) });

    const onSubmit = async ({ email, password }: { email: string, password: string }) => {
        const { data } = await onVerifyCredentials({
            variables: {
                email,
                password,
            },
        });

        if (data?.getWebToken) {
            const { accessToken, refreshToken } = data.getWebToken
            await setAuthCookies(accessToken, refreshToken);

            navigateToProductList();
        }
    };

    return (
        <Stack spacing={4}>
            <div>
                <Box component={RouterLink} href={paths.home} sx={{ display: 'inline-block', fontSize: 0 }}>
                    <Image
                        src="/assets/veolia-logo-with-background.svg"
                        alt="veolia logo"
                        style={{
                            marginLeft: '-40px',
                        }}
                        width={200}
                        height={40}
                    />
                </Box>
            </div>
            <Stack spacing={1}>
                <Typography variant="h5">Sign in</Typography>
            </Stack>
            <Stack spacing={3}>
                <Stack spacing={2}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Controller
                                control={control}
                                name="email"
                                render={({ field }) => (
                                    <FormControl error={Boolean(errors.email)}>
                                        <InputLabel>Email address</InputLabel>
                                        <OutlinedInput {...field} type="email" />
                                        {errors.email ? <FormHelperText>{errors.email.message}</FormHelperText> : null}
                                    </FormControl>
                                )}
                            />
                            <Controller
                                control={control}
                                name="password"
                                render={({ field }) => (
                                    <FormControl error={Boolean(errors.password)}>
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            {...field}
                                            endAdornment={
                                                showPassword ? (
                                                    <EyeIcon
                                                        cursor="pointer"
                                                        fontSize="var(--icon-fontSize-md)"
                                                        onClick={(): void => {
                                                            setShowPassword(false);
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        cursor="pointer"
                                                        fontSize="var(--icon-fontSize-md)"
                                                        onClick={(): void => {
                                                            setShowPassword(true);
                                                        }}
                                                    />
                                                )
                                            }
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                        />
                                        {errors.password ? <FormHelperText>{errors.password.message}</FormHelperText> : null}
                                    </FormControl>
                                )}
                            />
                            {error && (
                                <Alert severity="error">Invalid username or password</Alert>
                            )}
                            <Button disabled={isPending} type="submit" variant="contained">
                                Sign in
                            </Button>
                        </Stack>
                    </form>
                </Stack>
            </Stack>
        </Stack>
    );
}
